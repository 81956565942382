/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { useState, useEffect /*useContext*/ } from "react";
import Header from "./components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
// import profile from "./assets/profile.jpg";
// import { Redirect } from "react-router-dom";
// import { MainContext } from "./context/MainContext";
import { useToasts } from "react-toast-notifications";
// import { TimeConvertor } from "./utils/TimeConvertor";
// import districts from "../src/districs.json";
import area from "../src/area.json";
import { useLocation } from "react-router-dom";
// import CheckInCard from "./CheckInCard";
import { useHistory } from "react-router-dom";

export default function Registraion() {
  const { addToast } = useToasts();

  const history = useHistory();

  const [rdata, setData] = useState({
    name: "",
    gender: "",
    age: "",
    country_code: "",
    phone: "",
    country: "India",
    state: "",
    district: "",
    other_district: "",
    area: "",
    native_place: "",
    whatsapp: "",
    email: "",
    campus: "",
    campus_country: "India",
    campus_state: "kerala",
    campus_district: "",
    course_type: "",
    course: "",
    year: "",
    year_of_study: "",
    year_of_completion: "",
  });
  // const [ievents, setIevents] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [eemail, setEemail] = useState({});
  // const [logok, setLogok] = useState(false);
  const [countryCode, setCountryCode] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [otherDistrict, setOtherDistrict] = useState("");
  const [otherCampus, setOtherCampus] = useState("");
  const [campusList, setCampusList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [otherCourse, setOtherCourse] = useState("");
  const [checkInId, setCheckInId] = useState("");

  // const { spevents } = useContext(MainContext);
  const location = useLocation();
  const currentRoute = location.pathname.replace(/\//g, "");

  useEffect(() => {
    getCountryCode();
    getDistricts();
    getCourseTypeList();

    // var phone = localStorage.getItem("phone");
    // var code = localStorage.getItem("code");

    // fetch("https://api.profcon.in/r/get-student/" + code + phone + "/")
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (res) {
    //     if (res.data) {
    //       setData({
    //         ...rdata,
    //         name: res.data.name,
    //         email: res.data.email,
    //         campus: res.data.campus,
    //         native_place: res.data.native_place,
    //         campus: res.data.campus,
    //         gender: res.data.gender,
    //         course: res.data.course,
    //         country_name: res.data.native_country,
    //       });
    //     }
    //   });
    // if (localStorage.getItem("code") && localStorage.getItem("phone")) {
    //   setLogok(true);
    // }
  }, []);

  const getCourseList = (course_type) => {
    fetch(
      `https://guide.msoftit.com/api/get-from-guide/course_list?course_type=${course_type}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("COURSELIST: ", data.data.list);
        setCourseList(data.data.list);
      });
  };

  const getCourseTypeList = () => {
    fetch("https://guide.msoftit.com/api/get-from-guide/course_types")
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("COURSETYPELIST: ", data.data.list);
        setCourseTypeList(data.data.list);
      });
  };

  const getCampusList = (course_type) => {
    fetch(
      `https://guide.msoftit.com/api/get-from-guide/campus_list?district_id=${rdata.campus_district}&&stream_id=${course_type}}`
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("CAMPUSLIST: ", data.data.list);
        setCampusList(data.data.list);
      });
  };

  const getDistricts = () => {
    fetch("https://guide.msoftit.com/api/get-from-guide/districts")
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("DISTRICTS: ", data.data.list);

        setDistricts(data.data.list);
      });
  };

  const getCountryCode = () => {
    fetch("https://static.cdn.tfora.in/country/countries.json")
      .then(function (response) {
        // console.log(response, "kooi");
        return response.json();
      })
      .then(function (data) {
        console.log("COUNTRIES: ", data.countries);
        setCountryCode(data.countries);
      });
  };

  const handleChange = (event) => {
    if (event.target.name == "district") {
      setOtherDistrict("");
    }

    setData({ ...rdata, [event.target.name]: event.target.value });

    if (event.target.name == "course_type") {
      console.log(event.target.value);
      getCampusList(event.target.value);
      getCourseList(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log(currentRoute);

    console.log(rdata);
    const newErrors = {};
    let errorsPresent = false;
    const requiredFields = [
      "name",
      "gender",
      "state",
      "district",
      "native_place",
      "phone",
      "age",
      "campus_district",
      "course_type",
      "campus",
    ];

    for (const field of requiredFields) {
      if (field === "district" && rdata.state !== "kerala") {
        continue;
      }
      if (!rdata[field]?.trim()) {
        newErrors[field] = `${capitalizeFirstLetter(field)} is required`;
        errorsPresent = true;
      }
    }

    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

    // Remove any previous errors that are no longer relevant
    setErrors((prevErrors) => {
      requiredFields.forEach((field) => {
        if (rdata[field]?.trim()) {
          delete prevErrors[field];
        }
      });
      return { ...prevErrors };
    });

    if (errorsPresent) {
      addToast("Invalid fields present", {
        appearance: "error",
      });
      setIsSubmitting(false);
      return;
    }

    // Utility function to capitalize the first letter of a string
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const c_name = countryCode.filter((c) => c.phoneCode == rdata.country_code);
    console.log(c_name, "c_name");

    let data = new FormData();

    data.append("name", rdata.name);
    data.append("mobile", rdata.phone);
    data.append("event_type", "profcon");
    data.append("event_id", 51);
    data.append("age", rdata.age);
    data.append("gender", rdata.gender);
    data.append("country", "India");
    data.append("state", rdata.state);
    data.append("district", rdata.district);
    data.append("area", rdata.area);
    data.append("native_place", rdata.native_place);
    data.append("email", rdata.email);
    data.append("whatsapp", rdata.whatsapp);

    data.append("year_of_study", rdata.year_of_study);
    data.append("year_of_completion", rdata.year_of_completion);
    data.append("campus_district", rdata.campus_district);
    data.append("other_district", otherDistrict);
    data.append("campus", rdata.campus);
    data.append("other_college", otherCampus);
    data.append("course_type", rdata.course_type);
    data.append("course", rdata.course);
    data.append("other_course", otherCourse);

    
    if (currentRoute === "donateContact") {
      data.append("donation", true);
    }

    if (currentRoute === "selfcheckin") {
      data.append("self-checkin", true);
    }

    console.log(Array.from(data.entries()), "formdata");

    var config = {
      method: "POST",
      url: "https://guide.msoftit.com/api/e-event-registration",
      data: data,
    };

    axios(config)
      .then((res) => {
        console.log("RESPONSE: ", res);
        if (
          res.data.message === "Registered successfully." ||
          res.data.message === "Check-in successfully."
        ) {
          setIsSubmitting(false);
          setData({
            name: "",
            gender: "",
            age: "",
            country_code: "",
            phone: "",
            country: "India",
            state: "",
            district: "",
            other_district: "",
            area: "",
            native_place: "",
            whatsapp: "",
            email: "",
            campus: "",
            campus_country: "India",
            campus_state: "kerala",
            campus_district: "",
            course_type: "",
            course: "",
            year: "",
            year_of_study: "",
            year_of_completion: "",
          });
          if (currentRoute === "selfcheckin") {
            addToast(
              "You are successfully checked-in for 28th Professional Students Conference",
              {
                appearance: "success",
              }
            );

            setCheckInId(res.data.data.candidate_id);
            history.push(`/checkin-successful/${res.data.data.candidate_id}`);
          } else {
            addToast(
              "You have successfully registered for the 28th Professional Students Conference.",
              {
                appearance: "success",
              }
            );
          }

          // setTimeout(() => {
          //   window.location.href = "/";
          // }, 3000);
        } else {
          setIsSubmitting(false);
          addToast(res.data.message, {
            appearance: "error",
          });
        }
      })
      .catch((e) => {
        setIsSubmitting(false);
        addToast("Something went wrong!", {
          appearance: "error",
        });
        console.log(e);
      });
  };

  const getTitle = () => {
    if (currentRoute === "donateContact") {
      return "Donate a Contact";
    }

    if (currentRoute === "selfcheckin") {
      return "Self Check-in";
    }

    return "Registration";
  };

  const getButtonName = () => {
    if (currentRoute === "donateContact") {
      return "Register";
    }

    return "Donate a Contact";
  };
  const getButtonLink = () => {
    if (currentRoute === "donateContact") {
      return "/registration";
    }

    return "/donateContact";
  };

  return (
    <>
      {" "}
      <Header />
      <div className="registration">
        <div className="container">
          <div className="my-5">
            <div className="d-flex justify-content-between my-4">
              <h2 className="text-black">{getTitle()}</h2>
              <Link to={getButtonLink()}>
                <button type="button" className="bg-primary">
                  {getButtonName()}
                </button>
              </Link>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <input
                    name="name"
                    type="text"
                    value={rdata.name}
                    placeholder="Name"
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <small className="text-danger me-1">{errors.name}</small>
                  )}
                </div>
                <div className="col-md-3 mb-4">
                  <select
                    value={rdata.gender}
                    name="gender"
                    onChange={handleChange}
                  >
                    <option>Select Gender</option>
                    <option name="gender" value="M">
                      Male
                    </option>
                    <option name="gender" value="F">
                      Female
                    </option>
                  </select>
                  {errors.gender && (
                    <small className="text-danger me-1">{errors.gender}</small>
                  )}
                </div>
                <div className="col-md-3 mb-4">
                  <input
                    type="number"
                    placeholder="Age"
                    name="age"
                    onChange={handleChange}
                  />
                  {errors.age && (
                    <small className="text-danger me-1">{errors.age}</small>
                  )}
                </div>

                {/* <div className="col-md-3 mb-4">
                  <div className="d-flex">
                    <select
                      placeholder="Country"
                      className=""
                      name="country"
                      onChange={handleChange}
                      value={rdata.country}
                    >
                      <option value="">Select Country</option>
                      {countryCode.map((i) => (
                        <option name="country" value={i.name}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.country && (
                    <small className="text-danger me-1">{errors.country}</small>
                  )}
                </div> */}
                {rdata.country && rdata.country == "India" && (
                  <>
                    <div className="col-md-3 mb-4">
                      <select
                        value={rdata.state}
                        name="state"
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>
                        <option name="state" value="kerala">
                          kerala
                        </option>
                        <option name="state" value="outkerala">
                          Out of Kerala
                        </option>
                      </select>
                      {errors.state && (
                        <small className="text-danger me-1">
                          {errors.state}
                        </small>
                      )}
                    </div>
                    {rdata.state == "kerala" && rdata.state && (
                      <>
                        <div className="col-md-3 mb-4">
                          <select
                            value={rdata.district}
                            name="district"
                            onChange={handleChange}
                          >
                            <option value="">Select District</option>
                            {districts.map((i) => (
                              <option
                                name="district"
                                key={i.item_value}
                                value={i.item_value}
                              >
                                {i.name}
                              </option>
                            ))}
                          </select>
                          {errors.district && (
                            <small className="text-danger me-1">
                              {errors.district}
                            </small>
                          )}
                        </div>

                        {rdata.district !== "others" ? (
                          <div className="col-md-3 mb-4">
                            <select
                              placeholder="Area"
                              className="me-3"
                              name="area"
                              onChange={handleChange}
                              value={rdata.area}
                              defaultValue={rdata.area}
                            >
                              <option value="" disabled>
                                Select area
                              </option>
                              {area
                                .filter((f) => f.item_value == rdata.district)
                                .map((i) => (
                                  <option
                                    name="country"
                                    key={i.guide_id}
                                    value={i.guide_id}
                                  >
                                    {i.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        ) : (
                          <div className="col-md-3 mb-3">
                            <input
                              name="other_district"
                              type="text"
                              value={otherDistrict}
                              placeholder="Place"
                              onChange={(e) => {
                                setOtherDistrict(e.target.value);
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {/* <div className="col-md-3 mb-4">
                      <input
                        type="email"
                        name="email"
                        value={rdata.email}
                        placeholder="Email"
                        onChange={handleChange}
                      />
                      {errors.email && <small className="text-danger me-1">{errors.email}</small>}
                    </div>

                    <div className="col-md-3 mb-4">
                      <div className="d-flex">
                        <select
                          style={{ width: "100px" }}
                          placeholder="Country code"
                          className="me-3"
                          name="country_code"
                          onChange={handleChange}
                          value={rdata.country_code}
                        >
                          <option>Code</option>
                          {countryCode.map((i) => (
                            <option name="country" value={"+" + i.phoneCode}>
                              +{i.phoneCode}
                            </option>
                          ))}
                        </select>
                        <input
                          type="number"
                          name="phone"
                          placeholder="Phone"
                          value={rdata.phone}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.country_code && (
                        <small className="text-danger me-1">{errors.country_code}</small>
                      )}
                      {errors.phone && <small className="text-danger me-1">{errors.phone}</small>}
                    </div>
                    <div className="col-md-3 mb-4">
                      <input
                        value={rdata.whatsapp}
                        name="whatsapp"
                        type="number"
                        onChange={handleChange}
                        placeholder="Whatsapp"
                      />

                      {errors.whatsapp && (
                        <small className="text-danger me-1">{errors.whatsapp}</small>
                      )}
                    </div> */}
                    <div className="col-md-3 mb-4">
                      <input
                        type="text"
                        name="native_place"
                        placeholder="Native Place"
                        value={rdata.native_place}
                        onChange={handleChange}
                      />
                      {errors.native_place && (
                        <small className="text-danger me-1">
                          {errors.native_place}
                        </small>
                      )}
                    </div>
                  </>
                )}

                <div className="col-md-3 mb-4">
                  <input
                    type="email"
                    name="email"
                    value={rdata.email}
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <small className="text-danger me-1">{errors.email}</small>
                  )}
                </div>

                <div className="col-md-3 mb-4">
                  <div className="d-flex">
                    <select
                      style={{ width: "100px" }}
                      placeholder="Country code"
                      className="me-3"
                      name="country_code"
                      onChange={handleChange}
                      value={rdata.country_code}
                    >
                      {/* <option >Code</option> */}
                      {countryCode.map((i, index) => (
                        <option
                          name="country"
                          key={index}
                          value={"+" + i.phoneCode}
                        >
                          +{i.phoneCode}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone"
                      value={rdata.phone}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.country_code && (
                    <small className="text-danger me-1">
                      {errors.country_code}
                    </small>
                  )}
                  {errors.phone && (
                    <small className="text-danger me-1">{errors.phone}</small>
                  )}
                </div>
                <div className="col-md-3 mb-4">
                  <input
                    value={rdata.whatsapp}
                    name="whatsapp"
                    type="number"
                    onChange={handleChange}
                    placeholder="Whatsapp"
                  />

                  {errors.whatsapp && (
                    <small className="text-danger me-1">
                      {errors.whatsapp}
                    </small>
                  )}
                </div>

                {/* <div className="col-12 mb-4">
                  <div className="spevents">
                    <h5 className="text-white">Select Special Events</h5>
                    <div className="row">
                      {spevents
                        ? spevents.map((i) => (
                            <div className="col-md-4" key={i.id}>
                              <div className="scheduleList mb-3">
                                <div className="scheduleEvent">
                                  <div className="schedCard">
                                    <div className="d-flex justify-content-between">
                                      <h4 className="text-white">
                                        {i.event_name}
                                      </h4>
                                      <div style={{ whiteSpace: "nowrap" }}>
                                        <h5
                                          onClick={() => addToList(i.id)}
                                          className="m-0 text-white btn"
                                        >
                                          {ievents.includes(i.id) ? (
                                            <h5 className="m-0 text-white">
                                              Remove
                                            </h5>
                                          ) : (
                                            <h5 className="m-0 text-white">
                                              Add
                                            </h5>
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                    <h6 className="text-white">
                                      <TimeConvertor time={i.from_time} /> –{" "}
                                      <TimeConvertor time={i.to_time} />
                                    </h6>
                                    <div className="speaker d-flex align-items-center">
                                      <div className="d-flex me-2">
                                        {i.speaker.map((s) => (
                                          <div className="sProfile">
                                            {s.photo.small_square ? (
                                              <img
                                                src={s.photo.small_square}
                                                title="speaker"
                                                alt={s.name}
                                                className="img-fluid"
                                              />
                                            ) : (
                                              <img
                                                src={profile}
                                                title="speaker"
                                                alt={s.name}
                                                className="img-fluid"
                                              />
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                      {i.speaker.slice(0, 2).map((n) => (
                                        <h6 className="mb-0 text-white ms-1">
                                          {n.name},
                                        </h6>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                                      </div> */}
              </div>
              <hr className="text-black" />
              <h4 className="text-black">Campus details</h4>
              <div className="row">
                {/* <div className="col-md-3 mb-4">
                  <div className="d-flex">
                    <select
                      placeholder="Campus country"
                      className="me-3"
                      name="campus_country"
                      onChange={handleChange}
                      value={rdata.campus_country}
                    >
                      <option>Select Country</option>
                      {countryCode.map((i) => (
                        <option name="country" value={i.name}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.campus_country && (
                    <small className="text-danger me-1">{errors.campus_country}</small>
                  )}
                </div> */}

                {rdata.campus_country && rdata.campus_country == "India" && (
                  <>
                    {/* <div className="col-md-3 mb-4">
                      <select
                        value={rdata.campus_state}
                        name="campus_state"
                        onChange={handleChange}
                      >
                        <option>Select Campus State</option>
                        <option name="campus_state" value="kerala">
                          kerala
                        </option>
                        <option name="campus_state" value="outkerala">
                          Out of Kerala
                        </option>
                      </select>
                      {errors.campus_state && (
                        <small className="text-danger me-1">{errors.campus_state}</small>
                      )}
                    </div> */}

                    {rdata.campus_state && rdata.campus_state == "kerala" && (
                      <div className="col-md-3 mb-4">
                        <select
                          value={rdata.campus_district}
                          name="campus_district"
                          onChange={handleChange}
                        >
                          <option value="">Select District</option>
                          {districts.map((i) => (
                            <option
                              name="campus_district"
                              key={i.item_value}
                              value={i.item_value}
                            >
                              {i.name}
                            </option>
                          ))}
                        </select>
                        {errors.campus_district && (
                          <small className="text-danger me-1">
                            {errors.campus_district}
                          </small>
                        )}
                      </div>
                    )}
                  </>
                )}

                {rdata.campus_district === "others" ? (
                  <div className="col-md-3 mb-3">
                    <input
                      name="other_district"
                      type="text"
                      value={otherDistrict}
                      placeholder="Place"
                      onChange={(e) => {
                        setOtherDistrict(e.target.value);
                      }}
                    />
                  </div>
                ) : null}

                <div className="col-md-3 mb-4">
                  <select
                    value={rdata.course_type}
                    name="course_type"
                    onChange={handleChange}
                  >
                    <option value="">Select Course Type</option>
                    {courseTypeList.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                  {errors.course_type && (
                    <small className="text-danger me-1">
                      {errors.course_type}
                    </small>
                  )}
                </div>

                <div className="col-md-3 mb-4">
                  <select
                    value={rdata.campus}
                    name="campus"
                    onChange={handleChange}
                  >
                    <option value="">Select Campus</option>
                    {campusList.map((i) => (
                      <option value={i.id}>{i.name}</option>
                    ))}
                    <option value="other">Other</option>
                  </select>
                  {errors.campus && (
                    <small className="text-danger me-1">{errors.campus}</small>
                  )}
                </div>

                {rdata.campus === "other" ? (
                  <div className="col-md-3 mb-4">
                    <input
                      type="text"
                      name="other_campus"
                      placeholder="Campus"
                      value={otherCampus}
                      onChange={(e) => setOtherCampus(e.target.value)}
                    />
                    {errors.otherCampus && (
                      <small className="text-danger me-1">
                        {errors.otherCampus}
                      </small>
                    )}
                  </div>
                ) : null}
                {/* <div className="col-md-3 mb-4">
                  <input
                    type="text"
                    name="course"
                    placeholder="Course"
                    value={rdata.course}
                    onChange={handleChange}
                  />
                  {errors.course && <small className="text-danger me-1">{errors.course}</small>}
                </div> */}
                <div className="col-md-3 mb-4">
                  <select
                    value={rdata.course}
                    name="course"
                    onChange={handleChange}
                  >
                    <option>Select Course</option>
                    {courseList.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.name}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </select>
                  {/* <input
                    type="text"
                    placeholder="Campus"
                    name="campus"
                    value={rdata.campus}
                    onChange={handleChange}
                  />
                  {errors.campus && <small className="text-danger me-1">{errors.campus}</small>} */}
                </div>

                {rdata.course === "other" ? (
                  <div className="col-md-3 mb-4">
                    <input
                      type="text"
                      name="other_course"
                      placeholder="Course"
                      value={otherCourse}
                      onChange={(e) => setOtherCourse(e.target.value)}
                    />
                  </div>
                ) : null}

                <div className="col-md-3 mb-4">
                  <select
                    value={rdata.year_of_study}
                    name="year_of_study"
                    onChange={handleChange}
                  >
                    <option>Select Year of Study</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>

                <div className="col-md-3 mb-4">
                  <select
                    value={rdata.year_of_completion}
                    name="year_of_completion"
                    onChange={handleChange}
                  >
                    <option>Select Year of Completion</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2029</option>
                  </select>
                </div>
              </div>
              <div className="d-flex">
                <button
                  className="me-3 bg-danger"
                  type="reset"
                  onClick={() => {
                    setData({
                      name: "",
                      gender: "",
                      age: "",
                      country_code: "",
                      phone: "",
                      country: "India",
                      state: "",
                      district: "",
                      other_district: "",
                      area: "",
                      native_place: "",
                      whatsapp: "",
                      email: "",
                      campus: "",
                      campus_country: "India",
                      campus_state: "kerala",
                      campus_district: "",
                      course_type: "",
                      course: "",
                      year: "",
                      year_of_study: "",
                      year_of_completion: "",
                    });
                    setErrors({});
                  }}
                >
                  RESET
                </button>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "⏳ Submitting..." : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
