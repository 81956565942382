import React, { useEffect } from "react";
import Slider from "react-slick";
import AOS from "aos";

import People1 from "../assets/2024/gallery/people(1).png";
import People2 from "../assets/2024/gallery/people(2).png";
import People3 from "../assets/2024/gallery/people(3).png";
import People4 from "../assets/2024/gallery/people(4).png";
import Speech1 from "../assets/2024/gallery/speach-single(1).png";
import Speech2 from "../assets/2024/gallery/speach-single(2).png";
import Speech3 from "../assets/2024/gallery/speach-single(3).png";
import Speech4 from "../assets/2024/gallery/speach-single(4).png";
import SpeechBox1 from "../assets/2024/gallery/speachbox(1).png";
import SpeechBox2 from "../assets/2024/gallery/speachbox(2).png";
import SpeechBox3 from "../assets/2024/gallery/speachbox(3).png";
import SpeechBox4 from "../assets/2024/gallery/speachbox(4).png";
import SpeechBox5 from "../assets/2024/gallery/speachbox(5).png";
import SpeechBox6 from "../assets/2024/gallery/speachbox(6).png";
import Banner1 from "../assets/banners/banner1.jpg";
import Banner2 from "../assets/banners/banner2.jpg";
import Banner3 from "../assets/banners/banner3.jpg";
import Banner4 from "../assets/banners/banner4.jpg";
import Banner5 from "../assets/banners/banner5.jpg";
import Banner6 from "../assets/banners/banner6.jpg";
import Header from "./Header";
import Footer from "./Footer";

function Gallery() {
  useEffect(() => {
    // Initialize AOS for scroll animations
    AOS.init({ once: true });
  }, []);

  const settings1 = {
    infinite: true,
    autoplay: true,
    centerMode: true,
    speed: 900,
    slidesToShow: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    autoplay: true,
    centerMode: true,
    speed: 1200,
    slidesToShow: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const settings3 = {
    infinite: true,
    autoplay: true,
    centerMode: true,
    speed: 1500,
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const settings4 = {
    infinite: true,
    autoplay: true,
    centerMode: true,
    speed: 1200,
    slidesToShow: 3,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <section id="gallery">
        <div className="container">
          <h1
            className="head-title"
            data-aos="fade-up"
            data-aos-duration="1300"
          >
            Gallery
          </h1>
        </div>
        <div className="">
          {/* <div className="gallery-title"><!-- <h1>Gallery</h1> --></div> */}
          <Slider
            className="slick-items  gallery-item1"
            data-aos="fade-up"
            data-aos-duration="1000"
            {...settings1}
          >
            <div className="gallery-single-item">
              <img src={Speech1} alt="procon-gallery images" width="100%" />{" "}
            </div>
            <div className="gallery-single-item">
              <img src={Speech2} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Speech3} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Speech4} alt="procon-gallery images" width="100%" />
            </div>
          </Slider>
        </div>

        <div className="">
          {/* <div className="gallery-title"></div> */}
          <Slider
            className="slick-items gallery-item2"
            data-aos="fade-right"
            data-aos-duration="1300"
            {...settings4}
          >
            <div className="gallery-single-item">
              <img src={People1} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={People2} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={People3} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={People4} alt="procon-gallery images" width="100%" />
            </div>
          </Slider>
        </div>

        <div className="">
          {/* <div className="gallery-title"></div> */}
          <Slider
            className="slick-items gallery-item2"
            data-aos="fade-left"
            data-aos-duration="1300"
            {...settings2}
          >
            <div className="gallery-single-item">
              <img src={SpeechBox1} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={SpeechBox2} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={SpeechBox3} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={SpeechBox4} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={SpeechBox5} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={SpeechBox6} alt="procon-gallery images" width="100%" />
            </div>
          </Slider>
        </div>

        <div className="">
          {/* <div className="gallery-title"></div> */}
          <Slider
            className="slick-items"
            data-aos="fade-right"
            data-aos-duration="1300"
            {...settings3}
          >
            <div className="gallery-single-item">
              <img src={Banner1} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Banner2} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Banner3} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Banner4} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Banner5} alt="procon-gallery images" width="100%" />
            </div>
            <div className="gallery-single-item">
              <img src={Banner6} alt="procon-gallery images" width="100%" />
            </div>
          </Slider>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Gallery;
