import React, { useState } from "react";
// import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { MainContext } from "../context/MainContext";
// import { useToasts } from "react-toast-notifications";
// import { useSubscription, useQuery } from "@apollo/react-hooks";
// import gql from "graphql-tag";
// import Logo from "../assets/logoprofconround.png";
import ProfconLogo from "../assets/2024/logo-black.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Header() {
  // const [notfy, setNotify] = useState(false);
  // const { user, isauthenticated } = useContext(MainContext);
  // const { addToast } = useToasts();

  // const getNotification = gql`
  //   subscription {
  //     general_notification(order_by: { id: desc }, limit: 1) {
  //       text
  //       name
  //       image
  //       id
  //     }
  //   }
  // `;
  //  const { loading, error, data } = useSubscription(getNotification);

  // const logOut = () => {
  //   localStorage.clear();
  //   window.location.href = "/";
  // };

  // useEffect(() => {
  //   if (!localStorage.getItem("d_id")) {
  //     setNotify(true);
  //     setTimeout(() => {
  //       setNotify(false);
  //     }, 20000);
  //   }
  // }, []);

  // function openMenu() {
  //   document.querySelector(".mobileMenu").style.display = "block";
  // }

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  // const closeMenu = () => {
  //   setIsActive(false);
  // };

  return (
    <header id="nav-items">
      <div className="container">
        <div className="nav-items">
          <Link className="logo" to="/">
            <img src={ProfconLogo} alt="profcon-2023 icon" width="100%" />
          </Link>
          {/* <div className="logo">
            <img src={ProfconLogo} alt="profcon-2023 icon" width="100%" />
          </div> */}
          <div className={`nav-menu ${isActive ? "nav-active" : ""}`}>
            <ul>
              <Link className="nav-link nav-link-ltr" to="/">
                <li>Home</li>
              </Link>
              <Link className="nav-link nav-link-ltr" to="/about">
                <li>About</li>
              </Link>
              <Link className="nav-link nav-link-ltr" to="/gallery">
                <li>Gallery</li>
              </Link>
              <Link className="nav-link nav-link-ltr" to="/news">
                <li>News Article</li>
              </Link>
            </ul>
          </div>
          <div className="reg-bt">
            <Link to="/registration">Register Now</Link>
          </div>
          <div
            className={`toggle-icon ${isActive ? "is-active" : ""}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>

    // <header className="p-3">
    //   <div className="conatiner">
    //     <div className="mobileMenuBtn ">
    //       <div className="d-flex align-items-center justify-content-between">
    //         <svg
    //           onClick={() => openMenu()}
    //           viewBox="0 0 24 24"
    //           width="30"
    //           height="30"
    //           stroke="#fff"
    //           strokeWidth="1.5"
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           fill="none"
    //           shapeRendering="geometricPrecision"
    //           className="menuButton"
    //         >
    //           <path d="M3 12h18"></path>
    //           <path d="M3 6h18"></path>
    //           <path d="M3 18h18"></path>
    //         </svg>
    //         <div className="logo ms-4">
    //           <h1 className="mb-0 mt-0">
    //             <Link className="text-white" to="/">
    //             </Link>
    //           </h1>
    //         </div>
    //         <div className="user cl">
    //           {isauthenticated ? (
    //             <Dropdown
    //               text={user.name && user.name.slice(0, 1) + " ▾"}
    //               pointing="top right"
    //               title={user.name && user.name}
    //             >
    //               <Dropdown.Menu>
    //                 <div className="menuOptions">
    //                   <h5 className="mb-1 mt-0">
    //                     <Link className="text-dark" to="/profile">
    //                       Profile
    //                     </Link>
    //                   </h5>
    //                   <h5 className="mb-1 mt-3" onClick={() => logOut()}>
    //                     Logout
    //                   </h5>
    //                 </div>
    //               </Dropdown.Menu>
    //             </Dropdown>
    //           ) : (
    //             <h1 className="mb-0 mt-0">
    //               <Link className="text-white" to="/">
    //                 Login
    //               </Link>
    //             </h1>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="d-flex justify-content-between align-items-center menuWeb">
    //       <div className="logo">
    //         <h1 className="mb-0 mt-0">
    //           <Link className="text-white" to="/">
    //           </Link>
    //         </h1>
    //       </div>

    //       <div className="user cl">
    //         {isauthenticated ? (
    //           <Dropdown
    //             text={user.name && user.name.slice(0, 1) + " ▾"}
    //             pointing="top right"
    //             title={user.name && user.name}
    //           >
    //             <Dropdown.Menu className="">
    //               <div className="menuOptions">
    //                 <h5 className="mb-1 mt-0">
    //                   <Link className="text-dark" to="/profile">
    //                     Profile
    //                   </Link>
    //                 </h5>
    //                 <h5 className="mb-1 mt-3" onClick={() => logOut()}>
    //                   Logout
    //                 </h5>
    //               </div>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         ) : (
    //           <h1 className="mb-0 mt-0">
    //             <Link className="text-white" to="/register">
    //               Register
    //             </Link>
    //           </h1>
    //         )}
    //         </div>
    //     </div>
    //   </div>
    // </header>
  );
}
