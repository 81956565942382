import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import News1 from "../assets/2024/news/news(1).png";
import News2 from "../assets/2024/news/news(2).png";
import News3 from "../assets/2024/news/news(3).png";
import News4 from "../assets/2024/news/news(4).png";
import News5 from "../assets/2024/news/news(5).png";
import News6 from "../assets/2024/news/news(6).png";
import NewsLong from "../assets/2024/news/news-long.png";


function News() {
  return (
    <>
      <Header />
      <section id="news">
        <div class="container">
          <h1 class="head-title" data-aos="fade-up" data-aos-duration="1300">
            News Articles
          </h1>
        </div>
        <div class="">
          <div class=" news-items">
            <div class=" " data-aos="fade-up" data-aos-duration="1000">
              <img src={News5}  alt="" width="100%" />
            </div>
            <div class=" " data-aos="fade-up" data-aos-duration="1200">
              <img src={News4}  alt="" width="100%" />
            </div>
            <div class=" " data-aos="fade-up" data-aos-duration="1200">
              <img src={News6}  alt="" width="100%" />
            </div>
            <div class="" data-aos="fade-up" data-aos-duration="1200">
              <img src={News3}  alt="" width="100%" />
            </div>
            <div class=" " data-aos="fade-up" data-aos-duration="1200">
              <img src={News2}  alt="" width="100%" />
            </div>
            <div class=" " data-aos="fade-up" data-aos-duration="1200">
              <img src={News1}  alt="" width="100%" />
            </div>
            <div class=" " data-aos="fade-up" data-aos-duration="1200">
              <img src={NewsLong} alt="" width="100%" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default News;
