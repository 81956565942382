import React from "react";
import Slider from "react-slick";
import Banner1 from "../assets/banners/banner1.jpg";
import Banner2 from "../assets/banners/banner2.jpg";
import Banner3 from "../assets/banners/banner3.jpg";
import Banner4 from "../assets/banners/banner4.jpg";
import Banner5 from "../assets/banners/banner5.jpg";
import Banner6 from "../assets/banners/banner6.jpg";

const BannerCarousel = () => {
  const sliderSettings = {
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div id="bgslider">
      <Slider {...sliderSettings}>
        <div>
          <img className="banner1" src={Banner1} alt="Slider" />
        </div>
        <div>
          <img className="banner2" src={Banner2} alt="Slider" />
        </div>
        <div>
          <img className="banner3" src={Banner3} alt="Slider" />
        </div>
        <div>
          <img className="banner4" src={Banner4} alt="Slider" />
        </div>
        <div>
          <img className="banner5" src={Banner5} alt="Slider" />
        </div>
        <div>
          <img className="banner6" src={Banner6} alt="Slider" />
        </div>
      </Slider>
    </div>
  );
};

export default BannerCarousel;
