import React from "react";
import { Link } from "react-router-dom";
import ProfconIllu from "../assets/2024/Profcon_kkd illu.png";

function InviteSection() {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="invite-text">
              <h1 data-aos="fade-up-right" data-aos-duration="800">
                {" "}
                See You <br /> @Thiruvananthapuram{" "}
              </h1>
              <img
                src={ProfconIllu}
                alt=""
                width="100%"
                data-aos="fade-up"
                data-aos-duration="1300"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="invite-text">
              <h2 data-aos="fade-up" data-aos-duration="800">
                Book Your Seats Now !
              </h2>
              <a
                href="tel:+918156812292"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                {" "}
                <h3>📞+918156812292 </h3>
              </a>

              <div data-aos="fade-up" data-aos-duration="1600">
                <Link className="invite-link" to="/registration">
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InviteSection;
