import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
// import "aos/dist/aos.css";
import "./Style.css";

// import AOS from "aos";
import Landing from "../src/Landing";
// import Speakers from "./Speakers";
// import Home from "./Home";
import { useState, useEffect } from "react";
// import Stage from "./Stage";
// import Chats from "./Chats";
// import Expo from "./Expo";
import NothingFound from "./NothingFound";
import Register from "./Registraion";
// import CheckIn from "./CheckIn";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MobileMenu from "./components/MobileMenu";
// import { ApolloProvider } from "@apollo/client";
// import { client } from "./apollo";
import { MainProvider, MainContext } from "./context/MainContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
// import { PreviousVedios } from "./PreviousVedios";
// import Profile from "./Profile";
// import Feedback from "./Feedback";
// import { PrivateRoute, ProtectedRoute } from "./components/PrivateRoute";
import MainLoader from "./MainLoader";
// import { setCookie, deleteCookie } from "./utils/Cookie";
// import axios from "axios";
// import Player from "./Player";
// import EditProfile from "./EditProfile";
import About from "./About";
// import Resource from "./Resources";
// import Guidance from "./Guidance";
import ReactGA from "react-ga";
// import LiveButton from "./components/LiveButton";
// import CheckInCard from "./CheckInCard";
import Gallery from "./components/Gallery";
import News from "./components/News";

// ReactGA.initialize("UA-155211829-1");
// ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);
  // useEffect(() => {
  // document.addEventListener("contextmenu", function (e) {
  //   e.preventDefault();
  // });
  // document.onkeydown = function (e) {
  //   e = e || window.event; //Get event

  //   if (!e.ctrlKey) return;

  //   var code = e.which || e.keyCode; //Get key code

  //   switch (code) {
  //     case 73: //Block Ctrl+S
  //     case 85:
  //     case 117: //Block Ctrl+S
  //     case 105: //Block Ctrl+W -- Not work in Chrome and new Firefox
  //       e.preventDefault();
  //       e.stopPropagation();
  //       break;
  //   }
  // };
  // });
  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <ToastProvider
          placement="bottom-center"
          autoDismiss
          autoDismissTimeout={2000}
        >
          <Router>
            {/* <MobileMenu /> */}
            <Switch>
              <Route path="/" exact component={Landing} />

              <Route path="/register" component={Register} />
              <Route path="/registration" component={Register} />
              <Route path="/donatecontact" component={Register} />

              <Route path="/about" component={About} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/news" component={News} />

              <Route component={NothingFound} />
            </Switch>
          </Router>
        </ToastProvider>
      )}
    </>
  );
}

export default App;
