import React from "react";
import logo from "./assets/logoprofconround.png";

export default function MainLoader() {
  return (
    <div id="loader">
      <div className="roundAnimation">
        <div className="box bounce-1">
          <img src={logo} width="50" alt="loading..." />
        </div>
      </div>
    </div>
  );
}
