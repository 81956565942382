import React from "react";
import image from "./assets/404.png";
import Header from "./components/Header";
import { Link } from "react-router-dom";

export default function NothingFound() {
  return (
    <>
      <Header />
      <div className="d-flex justify-content-center align-item-center mt-5 w-100 h-100 flex-coloumn">
        <img
          src={image}
          alt="Nothing Found"
          title="404"
          className="img-fluid"
        />
      </div>
      <div className=" d-flex justify-content-center align-item-center mt-5 flex-coloumn">
        <Link to="/">
          <button
            type="submit"
            className="btn-primary rounded-4"
            style={{ borderRadius: 6, padding: "10px" }}
          >
            Back to Professional Students Conference
          </button>
        </Link>
      </div>
    </>
  );
}
