import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import InviteSection from "./components/InviteSection";

export default function About() {
  return (
    <>
      <Header />
      <section id="about">
        <div className="container">
          <div className="about-text">
            <div className="row">
              <h1
                className="head-title"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                About Us
              </h1>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <h3 data-aos="fade-up" data-aos-duration="1100">
                  GENERATIONS
                </h3>
                <p data-aos="fade-up" data-aos-duration="1300">
                  That's how we define history! & It's time of us, the Gen-Z.
                  Can you believe that we have generated data more than the last
                  1000 years during last 10 years. The age of internet
                  revolution. Everything changing into virtual world. In the
                  tapestry of the last century, we've woven remarkable
                  achievements: from the inception of primitive computers to the
                  dawn of artificial intelligence, from the first flight to
                  mankind's journey to Mars. Technological leaps have been
                  monumental.{" "}
                  <span>
                    But amid this progress, how do we stand on the spectrum of
                    MORALITY?
                  </span>
                  <br />
                  In a changing world, we can't turn a blind eye. Families
                  fracture, drug affliction infiltrates even the young, and
                  society grapples with distorted norms. To complicate matters,
                  purported scientific studies attempt to validate moral
                  ambiguities. Concurrently, extremist factions exploit
                  impressionable minds.
                  <br />A feeble youth hints at a dim future. We must not be
                  bystanders in this narrative. Society and time won't forgive
                  our apathy. Let's stand together to uphold Islam and being the
                  torch bearers.
                  <br />
                  Welcome to the{" "}
                  <span>PROFESSIONAL Students Global Conference</span>,
                  orchestrated by Wisdom Students. Twenty-seven years ago, a
                  cadre of professional students intimately familiar with campus
                  dynamics birthed an annual global congregation.{" "}
                  <span>"To the right goal, Through the right path"</span> we
                  are still on the journey uniting international students,
                  forging a potent force that rejuvenates ethics and empowers
                  youth, unfailingly. Since then, drastic changes has been
                  occurred. From the birth of social media giants to
                  teleportation, from unscientific pride movements to the
                  fascist holocausts, we have a lot to do and no time for
                  useless arguments.
                </p>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </section>

      <InviteSection />
      <Footer />

      {/* <div className="aboutPage ">
          <div className="container">
            <div className="about text-center d-flex justify-content-center">
              <div className="w-75">
                <h2 className="text-white mt-0">About Professional Students Conference</h2>
                <hr className="text-white" />
                <p className="text-white text-justify">
                  We realised the importance of moral upliftment of student
                  community and wished to contribute our part. Realising the
                  importance of discussions and deliberations, 27 years ago,
                  professional students forgathered in and event by the name{" "}
                  <b>Professional Students Conference</b>
                  under the slogan 'to the right goal, through the right path'.
                  The 28th instalment of <b>Professional Students Conference</b> is scheduled for 11, 12, 13 of 
                  October 2024 at Thiruvananthapuram.
                </p>
              </div>
            </div>
            <div className="contact mt-5 text-center d-flex justify-content-center">
              <div className="w-75">
                <h2 className="text-white mt-0">Contact Us</h2>
                <hr className="text-white" />
                <p className="text-white text-justify">
                  Want to know more about Professional Students Conference? Give us a call or drop us an
                  email and we will get back to you as soon as we can.
                </p>
                <div className="row mt-5">
                  <div className="col-md-4 mt-3">
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-phone"
                      >
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                      <h6 className="text-white mt-4">
                        <a className="text-white" href="tel:+917560902902">
                          +917560902902
                        </a>
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-map-pin"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                      <h6 className="text-white mt-4">
                        CV Complex
                        <br />
                        PV Swami Road, Kozhikode
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center my-5">
                  <div className="d-flex">
                    <a
                      href="https://www.instagram.com/wisdomstudents"
                      className="me-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-instagram"
                      >
                        <rect
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          ry="5"
                        ></rect>
                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                      </svg>
                    </a>
                    <a
                      href="https://www.facebook.com/wisdomstudents"
                      className="me-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-facebook"
                      >
                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                      </svg>
                    </a>
                    <a
                      href="https://www.twitter.com/wisdom_students"
                      className="me-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-twitter"
                      >
                        <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                      </svg>
                    </a>
                    <a href="https://t.me/wisdomstudents">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-send"
                      >
                        <line x1="22" y1="2" x2="11" y2="13"></line>
                        <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
}
