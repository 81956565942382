import React from "react";
import Header from "./components/Header";
import BannerCarousel from "./components/BannerCarousel";
import { Link } from "react-router-dom";
// import NowAt from "./components/Landing/NowAt";
// import gql from "graphql-tag";
// import { useSubscription, useQuery } from "@apollo/react-hooks";
// import Loader from "./Loader";
// import axios from "axios";
// import { useHistory } from "react-router-dom";
// import ChatBox from "./components/ChatBox";
// import { MainContext } from "./context/MainContext";
// import { useToasts } from "react-toast-notifications";
// import { Prev } from "./PreviousVedios";
// import Spotlogo from "./assets/spotlogo.png";
import RighGoal from "./assets/2024/right-goal-l.png";
import ProfconBanner from "./assets/2024/28th-prof-logo-with-date-and-place.png";
import GroupLeft from "./assets/2024/Group 123left.png";
import GroupRight from "./assets/2024/Group 124right.png";
import GlobeSvg from "./assets/2024/globe.svg";
import MountainSvg from "./assets/2024/mountain.svg";
import BagSvg from "./assets/2024/bag.svg";
import SunSvg from "./assets/2024/sun.svg";
import HandSvg from "./assets/2024/hand.svg";
import ChatSvg from "./assets/2024/chat.svg";
import SchoolSvg from "./assets/2024/school.svg";
import PeopleSvg from "./assets/2024/people.svg";
import FlowerSvg from "./assets/2024/flower.svg";

import CarouselPoints from "./components/CarouselPoints";
// import AOS from "aos";
import Footer from "./components/Footer";
import InviteSection from "./components/InviteSection";

export default function Landing(props) {
  // const [otpm, setOtpm] = useState({ status: false, m: "" });
  // const { addToast } = useToasts();
  // let history = useHistory();
  // const [otp, setOtp] = useState(false);
  // const [countryCode, setCountryCode] = useState([]);
  // const [errors, setErrors] = useState({});
  // const { isauthenticated } = useContext(MainContext);

  // useEffect(() => {
  //   var screenHeight = window.innerHeight;
  //   document.querySelector(".spotlight").style.height =
  //     screenHeight - 50 + "px";
  //   getCountryCode();
  // }, []);

  // const getCountryCode = () => {
  //   fetch("https://static.cdn.tfora.in/country/countries.json")
  //     .then(function (response) {
  //       // console.log(response, "kooi");
  //       return response.json();
  //     })
  //     .then(function (data) {
  //       // console.log(data.countries, "okoko");
  //       setCountryCode(data.countries);
  //     });
  // };

  // const [ldata, setLdata] = useState({ country: "", phone: "" });
  // const [odata, setOdata] = useState();

  // const handleChange = (event) => {
  //   setLdata({ ...ldata, [event.target.name]: event.target.value });
  //   // setErrors(handleValidate());
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   let errors = {};

  //   if (!ldata.phone) {
  //     errors.phone = "Phone is required";
  //   }
  //   if (ldata.country == "+91") {
  //     if (ldata.phone.length !== 10) {
  //       errors.phone = "Phone no must be 10 digit";
  //     }
  //   }
  //   if (!ldata.country) {
  //     errors.country = "Country is required";
  //   }
  //   setErrors(errors);

  //   localStorage.setItem("phone", ldata.phone);
  //   localStorage.setItem("code", ldata.country);

  //   if ((await Object.keys(errors).length) === 0) {
  //     var data = new FormData();

  //     data.append("phone", ldata.phone);
  //     data.append("country_code", ldata.country);

  //     var config = {
  //       method: "POST",
  //       url: "https://app.profcon.in/api/auth/student-login/",
  //       data: data,
  //     };
  //     axios(config).then((res) => {
  //       var scode = res.data.status;
  //       // var login_data = res.data.data;
  //       if (scode === 200) {
  //         //api.profcon.in/r/get-student/+917034137180/
  //         handleSubmitOTP();
  //       } else if (scode === 400) {
  //         setOtp(false);
  //         // history.push("/home")
  //       } else if (scode === 300) {
  //         history.push("/register");
  //       }
  //     });
  //   }
  // };

  // const handleChangeVOTP = (event) => {
  //   setOdata(event.target.value);
  // };
  // const handleSubmitVOTP = (event) => {
  //   event.preventDefault();
  //   var data = new FormData();

  //   data.append("phone", ldata.phone);
  //   data.append("otp", odata);

  //   var config = {
  //     method: "POST",
  //     url: "https://app.profcon.in/api/auth/verify-otp/",
  //     data: data,
  //   };
  //   axios(config).then((res) => {
  //     var scode = res.data.status;
  //     var login_data = res.data.data;
  //     if (scode === 200) {
  //       window.location.href = "/home";
  //       // history.push("/home");

  //       localStorage.setItem("login_data", JSON.stringify(login_data));
  //       setOtp(false);
  //       addToast("LoggedIn Successfully", {
  //         appearance: "success",
  //       });
  //     } else if (scode === 401) {
  //       // toast("Wow so easy !");
  //       addToast("Invalid OTP", {
  //         appearance: "success",
  //       });
  //     }
  //   });
  // };

  // const handleSubmitOTP = () => {
  //   var data = new FormData();

  //   data.append("phone", ldata.phone);
  //   data.append("country_code", ldata.country);

  //   var config = {
  //     method: "POST",
  //     url: "https://app.profcon.in/api/auth/send-otp/",
  //     data: data,
  //   };
  //   axios(config).then((res) => {
  //     var scode = res.data.status;
  //     var m = res.data.message;
  //     if (scode === 200) {
  //       setOtp(true);
  //       addToast(m, {
  //         appearance: "success",
  //       });
  //       setOtpm({ status: true, m: m });
  //     } else if (scode === 400) {
  //       //  history.push("/");
  //       setOtpm({ status: false, m: "" });
  //     }
  //   });
  // };

  return (
    <>
      <Header />

      <div className="full-height-container">
        {/* BANNER SECTION START  */}
        <BannerCarousel />
        {/* Banner Text Section */}
        <div className="container">
          <div className="banner-text">
            <div
              className="item1"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-duration="1800"
            >
              <img src={RighGoal} alt="" width="70%" />
            </div>
            <div className="item2" data-aos="fade-up" data-aos-duration="800">
              <img src={ProfconBanner} alt="" width="100%" />
            </div>
          </div>
        </div>

        {/* HEADER REG BUTTON   */}
        <div className="reg-bt2 regbt-none button-section" data-aos="fade-up">
          <Link className="my-3 py-4 px-8 fs-3" to="/registration">
            Register
          </Link>
          <Link className="my-3 py-3 px-6" to="/donateContact">
            Donate a contact
          </Link>
        </div>
      </div>

      {/* <!-- INTRO SECTION START --> */}
      <section id="intro">
        <div className="container">
          <div className="intro-text">
            <div className="row">
              <div className="col-md-2"></div>
              <div
                className="col-md-8"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h3>
                  Welcome to the PROFESSIONAL Students Global Conference,
                  orchestrated by Wisdom Students.{" "}
                </h3>
                <p className="mt-5">
                  Twenty-seven years ago, a cadre of professional students
                  intimately familiar with campus dynamics birthed an annual
                  global congregation. "
                  <span>To the right goal, Through the right path</span>" we are
                  still on the journey uniting international students, forging a
                  potent force that rejuvenates ethics and empowers youth,
                  unfailingly. Since then, drastic changes has been occurred.
                  From the birth of social media giants to teleportation, from
                  unscientific pride movements to the fascist holocausts, we
                  have a lot to do and no time for useless arguments.
                </p>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-12"></div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- INTRO SECTION END --> */}

      {/* <!-- HIGHLIGHT SECTION START --> */}
      <section id="highlights">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="highlight-text">
                <h1>
                  An Event <br /> Unlike <span>👍</span> <br />
                  Any Other
                </h1>
              </div>
            </div>
            <div className="col-md-6" id="counter">
              <div className="highlight-text">
                <div className="highlight-text-card col-md-6 col-6">
                  <div data-aos="fade-right" data-aos-duration="1200">
                    <h2 className="count percent" data-count="8">
                      <span className="percent">3</span>
                    </h2>
                    <p>days</p>
                  </div>
                </div>
                <div className="highlight-text-card col-md-6 col-6">
                  <div data-aos="fade-right" data-aos-duration="800">
                    <h2 className="count percent" data-count="6">
                      6
                    </h2>
                    <p>venues</p>
                  </div>
                </div>
                <div className="highlight-text-card col-md-6 col-6">
                  <div data-aos="fade-right" data-aos-duration="1400">
                    <h2 className="count percent" data-count="3">
                      <span className="percent">5</span>+
                    </h2>
                    <p>workshops</p>
                  </div>
                </div>
                <div className="highlight-text-card col-md-6 col-6">
                  <div data-aos="fade-right" data-aos-duration="600">
                    <h2 className="count" data-count="6">
                      <span className="percent">6</span>K+
                    </h2>
                    <p>delegates</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- HIGHLIGHT SECTION END --> */}

      {/* <!-- POINTS SECTION START --> */}
      <section id="points">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="left-img points-img"
                data-aos="zoom-in-up"
                data-aos-duration="1200"
              >
                <img
                  src={GroupLeft}
                  width="100%"
                  alt="profcon speech previous for 2023 profcon"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-points points">
                <ul>
                  <li data-aos="fade-up" data-aos-duration="800">
                    <img src={GlobeSvg} alt="global Conference" />
                    global Clubs
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1000">
                    <img src={MountainSvg} alt="global Conference" />
                    National Dlegates Summit
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1200">
                    <img src={BagSvg} alt="global Conference" />
                    Career Resolution
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1400">
                    <img src={SunSvg} alt="global Conference" />
                    Enlighting Workshops
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1600">
                    <img src={HandSvg} alt="global Conference" />
                    Guidance Centres
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="left-points points">
                <ul>
                  <li data-aos="fade-up" data-aos-duration="800">
                    <img src={ChatSvg} alt="global Conference" />
                    Open Debates
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1000">
                    <img src={SchoolSvg} alt="global Conference" />
                    Campus Conclaves
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1200">
                    <img src={PeopleSvg} alt="global Conference" />
                    Panel Conclaves
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1400">
                    <img src={FlowerSvg} alt="global Conference" />
                    Girls Gatherings
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="right-img points-img"
                data-aos="zoom-in-up"
                data-aos-duration="1200"
              >
                <img
                  src={GroupRight}
                  width="100%"
                  alt="profcon speech previous for 2024 profcon"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- POINTS SECTION END --> */}

      {/* <!-- CAROUSAL POINTS START --> */}
      <CarouselPoints />
      {/* <!-- CAROUSAL POINTS END --> */}

      {/* <!-- COMMITMENTS SECTION START--> */}
      <section id="commitments">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="commitments-title"
                data-aos="fade-down"
                data-aos-duration="800"
              >
                <h1>
                  Our <br /> Commitments
                </h1>
              </div>
            </div>
            <div className="dcards row">
              <div className="col-md-3 col-sm-6 col-12 my-4">
                <div
                  className="dcontent redblue"
                  data-aos="zoom-in-up"
                  data-aos-duration="800"
                >
                  <p>Utilize their abilities in nation building</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 my-4">
                <div
                  className="dcontent bluered"
                  data-aos="zoom-in-up"
                  data-aos-duration="1100"
                >
                  <p>
                    create awareness against terrorism and anti social
                    activities
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 my-4">
                <div
                  className="dcontent redblue"
                  data-aos="zoom-in-up"
                  data-aos-duration="1300"
                >
                  <p>
                    recapture this student generation with a strong moral
                    foundation and academic excellence.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 my-4">
                <div
                  className="dcontent bluered"
                  data-aos="zoom-in-up"
                  data-aos-duration="1500"
                >
                  <p>
                    Nurture civic sense and social responsibility among students
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- COMMITMENTS SECTION END--> */}

      <InviteSection />
      <Footer />

      {/* <div className="landing"> */}
      {/* <div className="container"> */}
      {/* <div className="spotlight">
            <h1>
              Professional Students
              <br />
              Global Conference
            </h1>
            <p className="my-4">11, 12, 13 - OCT/24</p>
            <p className="my-4 text-danger">THIRUVANANTHAPURAM</p>
            <Link to="/registration">
              <Button primary className="mt-2">
                Register
              </Button>
            </Link>
            <Link to="/donatecontact">
              <Button primary className="mt-2">
                Donate a Contact
              </Button>
            </Link>
            <img
              src={Wisdom}
              alt="wisdom logo"
              className="img-fluid wisLogo mt-3"
            />
          </div> */}

      {/*<div className="nowlive py-4">
            <div className="title mb-5 mt-4">
              <h2>NOW AT Professional Students Conference</h2>
            </div>
            <div className="row">
              <NowAt nothing="center" />
            </div>
          </div>

          <div className="previousEvents pb-5">
            <div className="title mb-5 mt-5">
              <h2>Professional Students Conference REWIND</h2>
            </div>
            <Prev limit="8" />
            <div className="go d-flex justify-content-center mt-4">
              <Link to="/previous" title="View all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right-circle"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 16 16 12 12 8"></polyline>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
              </Link>
            </div>
          </div>
          */}
      {/* </div> */}
      {/* </div> */}
      {/* <ChatBox />
      <hr /> */}
      {/*<div className="bottomMenu mt-3 mb-4">
        <div className="container">
          <div className="row">
           
            <div className="col-12 d-flex justify-content-center">
              <div className="btn" title="Go to resources">
                <Link to="/resources" className="text-dark">
                  RESOURCES
                </Link>
              </div>
              <div className="btn mx-3" title="Go to guidance">
                <Link to="/guidance" className="text-dark">
                  GUIDANCE
                </Link>
              </div>
              <div className="btn" title="Go to about">
                <Link to="/about" className="text-dark">
                  ABOUT
                </Link>
              </div>
            </div>
          </div>
        </div>
        </div> */}
      {/* <footer className="d-flex justify-content-between align-items-center p-3 flex-wrap">
        <h6 className="m-0">
          Copyright © 2024 Professional Students Conference. All rights reserved.
        </h6>

        <h6 className="m-0">
          Developed by{" "}
          <a className="text-white" target="blank" title="Wisdom IT Wing" href="#">
            Wisdom IT Wing
          </a>
        </h6>
      </footer> */}
    </>
  );
}
