import React, { useEffect } from "react";
import Slider from "react-slick";

const CarouselPoints = () => {


  const settings = {
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="carousel-points">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Slider
              {...settings}
              data-aos="fade-up"
              className="carousal-points"
              data-aos-duration="1200"
            >
              <div>
                Intellectual debates help grow pro-active involvement of youth
                in the society.
              </div>
              <div>
                Unparalleled gathering of professional students from various
                campuses.
              </div>
              <div>Separate venues in English, Malayalam, and Urdu.</div>
              <div>
                Academicians, technocrats, and scholars interact with students
                through a series of lectures, debates, workshops, open forums,
                panel discussions, etc.
              </div>
              <div>
                Guidance cell helps students to face the vulnerabilities of
                life.
              </div>
              <div>
                International book fair and education expo with the presence of
                leading publishers explores new avenues of knowledge.
              </div>
              <div>
                IT corner promotes the safe and sound use of technology.
              </div>
              <div>
                Career guidance sessions to help students set goals in their
                academic and professional life.
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselPoints;
