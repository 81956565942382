import React from "react";
import { Link } from "react-router-dom";
import LogoColor from "../assets/2024/ST-LOGO-COLOUR.png";
import FooterLogo from "../assets/2024/ftrlogo.png";


function Footer() {
  return (
   
    <section id="footer">
      <div className="">
        <div className="container">
          <div className="row footer">
            <div className="col-md-4">
              <div className="ftr-icon">
                <img src={FooterLogo} alt="" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftr-nav">
                <ul>
                  <h4>Quick Links</h4>
                  <li>
                    <Link className="nav-link nav-link-ltr" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link nav-link-ltr" to="/about">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link nav-link-ltr" to="/news">
                      News Article
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link nav-link-ltr" to="/gallery">
                      Gallery
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftr-nav">
                <ul>
                  <h4>Book Your Seats Now </h4>
                  <li>
                    <a href="tel:+918156812292"> 📞 +918156812292</a>
                  </li>
                  <li>
                    <Link to="/registration">
                      🌐 www.students.wisdomislam.org/registration
                    </Link>
                  </li>
                  <li>
                    <img src={LogoColor} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
